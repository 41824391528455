// $primary: #a51616;
@import url('https://fonts.googleapis.com/css?family=Gloria+Hallelujah|Josefin+Sans');

$primary: #8c191b; /* MAIN COLOR */
$secondary: #5fc7cd; /* SECONDARY COLOR */
$third: #db6c2a; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}
body {
	font-family: 'Josefin Sans', sans-serif;

}
h1, h2 {
	font-family: 'Gloria Hallelujah', cursive;

}
nav {
	z-index: 1000;
}
.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 100px;
		display: flex;
		font-size: 1.3em;
		align-items: center;
		@media (max-width: 767px) {
		    height: 45px;
		    display: inline-block;
		}
	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 388px) {
		margin-top: 14px;
	}
}
/** LOGIN FORM **/
@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;
	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}
@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;
	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}
.modal-dialog {
	max-width: 350px;
	text-align: center;
	margin: 6em auto;
	.close {display: none;}
	.modal-content {
		color: $primary;
		h2 {
			text-align:center;
		}
	}
	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}
.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}
input#username {
	margin-bottom: 20px;
}
.cta {
	padding: 20px 40px;
	background: transparent;
	color: $wht;
	font-size: 1.2em;
	margin: 5px;
	border-radius: 0px;
	border: 1px solid $wht;
	@media(max-width:767px){
			padding: 10px 20px;
			font-size: 1.2em;
	}
	&:hover{
		border-radius:20px;
		color: $wht;
		background:$secondary;
		transition: all 0.7s ease 0s;
	}
}
.hero-image {
	background: url("../img/bg1.jpg") center top/cover no-repeat;
	padding: 250px 0;
	@media (max-width: 767px){
		padding:150px 0;
	}
	h1 {
		color: $secondary;
		text-shadow: 1px 2px 1px #111;
	}
}
.hero-2 {
	background: url("../img/bg2.jpg") center top/cover no-repeat;
	padding: 250px 0;
	@media (max-width: 767px){
		padding: 20px 0;
	}
	h1 {
		color: $primary;
		font-weight: bold;
		text-shadow: 0px 0px 10px #fff;
	}
	p {
		font-size: 2em;
		color: #fff;
	}
}
.hero-3 {
	background: url("../img/bg3.jpg") center top/cover no-repeat;
	padding: 250px 0;
	@media (max-width: 767px){
		padding: 20px 0;
	}
	h1 {
		color: $primary;
		font-weight: bold;
		text-shadow: 0px 0px 10px #fff;
	}
	p {
		font-size: 2em;
		color: #fff;
		@media (max-width: 767px){
			font-size: 1.5em;
			line-height: 1.2em;
		}
	}
}
/** END LOGIN FORM **/
footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 1.3em;
	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}
